<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="mt-2">
      Notificaciones
      <template v-slot:actions>
        <v-icon color="primary" @click.native.stop="reloadALL()"
          >mdi-refresh</v-icon
        >
        <v-icon
          color="error"
          @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })"
        >
          mdi-close
        </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content :key="reload">
      <div style="background: transparent; padding: 16px; border-radius: 15px">
        <v-card class="white">
          <block v-if="loading"></block>
          <v-card-text>
            <v-col cols="12" class="tabsNh d-flex justify-lg-end">
              <v-btn small class="mr-1 btnclean" @click="cleanFilterparams">
                <v-icon>mdi-filter-remove</v-icon>
              </v-btn>
              <v-dialog max-width="60%" v-model="showFilter" inset>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small class="btnclean" v-bind="attrs" v-on="on">
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>
                </template>
                <v-sheet class="text-center">
                  <div class="my-3 pa-4">
                    <div>
                      <h3>Aplicar filtros</h3>
                      <v-row>
                        <v-col
                          v-if="
                            filterProducts.typebusiness == 'bloodtestlaboratory' || filterProducts.typebusiness == 'medicalclinic'
                          "
                          cols="12"
                          sm="12"
                        >
                          <v-autocomplete
                            outlined
                            dense
                            attach
                            :items="['Clientes', 'Negocios', 'Todos']"
                            v-model="filterProducts.typeusers"
                            label="Tipo de pacientes"
                            type="text"
                          ></v-autocomplete>
                        </v-col>
                        <v-col
                          v-if="filterProducts.typebusiness == 'veterinary'"
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-autocomplete
                            outlined
                            dense
                            :items="filtersData.kind"
                            item-text="kind"
                            item-value="kind"
                            v-model="filterProducts.kind"
                            :label="'Especie'"
                            type="text"
                            attach
                          ></v-autocomplete>
                        </v-col>

                        <v-col
                          v-if="filterProducts.typebusiness == 'veterinary'"
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-autocomplete
                            outlined
                            dense
                            :items="filtersData.breed"
                            item-text="breed"
                            item-value="breed"
                            v-model="filterProducts.breed"
                            label="Raza"
                            attach
                            type="text"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            outlined
                            dense
                            :items="filtersData.Gender"
                            item-text="Gender"
                            item-value="Gender"
                            v-model="filterProducts.gender"
                            label="Genero"
                            type="text"
                            attach
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete
                            outlined
                            dense
                            :items="filtersData.city"
                            item-text="city"
                            item-value="city"
                            v-model="filterProducts.city"
                            label="Ciudad"
                            type="text"
                            attach
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete
                            outlined
                            dense
                            :items="filtersData.state"
                            item-text="state"
                            item-value="state"
                            v-model="filterProducts.state"
                            label="Estado"
                            type="text"
                            attach
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="2">
                          <v-checkbox
                            v-model="useBirthday"
                            label="Cumpleaños"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="12" md="4">
                          <date-picker
                            label="Cumpleaños"
                            :disbaled="!useBirthday"
                            @dateSelected="
                              (param) => (filterProducts.birthday = param)
                            "
                          ></date-picker>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <h4>Edad</h4>
                          <vue-slider
                            class="mr-3 mt-3"
                            v-model="filterProducts.age"
                          ></vue-slider>
                        </v-col>
                      </v-row>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="error darken-1"
                          text
                          @click="showFilter = !showFilter"
                        >
                          Cerrar
                        </v-btn>

                        <v-btn
                          color="primary darken-1"
                          text
                          @click="closeFilterAndUpdateGrid"
                          >Filtrar</v-btn
                        >
                      </v-card-actions>
                    </div>
                  </div>
                </v-sheet>
              </v-dialog>
              <v-btn
                small
                class="ml-1 btnsave"
                v-if="
                  typebusiness == 'veterinary' &&
                  (rolesStatus.findIndex((role) => role.RoleName === 'admin') >
                    -1 ||
                    rolesStatus.findIndex((role) => role.RoleName === 'root') >
                      -1 ||
                    rolesStatus.findIndex((role) => role.ID_Role === 36) > -1)
                "
                @click="sendEmail"
              >
                <v-icon>mdi-email</v-icon>
              </v-btn>
              <!-- <a href="#" class="mx-2" @click.prevent.stop="showModal = true">
              <v-icon color="success">mdi-whatsapp</v-icon>
            </a>
            <a href="#" class="mx-2" @click.prevent.stop="showModal = true">
              <v-icon>mdi-message</v-icon>
            </a> -->
            </v-col>

            <div
              style="
                margin-top: 0.5rem;
                display: flex;
                justify-content: space-between;
              "
            >
              <!--  <div class="d-flex filter-btn " >
              <v-bottom-sheet v-model="showFilter" inset>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="btnclean" v-bind="attrs" v-on="on" style="margin-top: .6rem;"
                    >Filtros
                  </v-btn>
                 </template>
                <v-sheet class="text-center">
                  <div class="my-3 pa-4">
                    <div>
                      <h3>Aplicar filtros</h3>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field
                     v-model="filterProducts.kind"
                      label="Tipo"
                      type="text"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="6">
                    <v-text-field
                     v-model="filterProducts.breed"
                      label="Raza"
                      type="text"
                    >
                    </v-text-field>
                </v-col>
                   <v-col cols="12" sm="12" md="6">
                    <v-text-field
                     v-model="filterProducts.city"
                      label="Ciudad"
                      type="text"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="filterProducts.state"
                      label="Estado"
                      type="text"
                    >
                    </v-text-field>
                </v-col>
                 <v-col cols="12" sm="12" md="4">
                  <date-picker
                    label="Cumpleaños"
                     @dateSelected="(param) => (filterProducts.birthday = param)"
                  ></date-picker>
                </v-col>
                   <v-col cols="12" sm="12" md="8">
                   <h4>Edad</h4>
                      <vue-slider class="mr-3 mt-3 " v-model="filterProducts.age"
                      ></vue-slider>
                  </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-btn color="error darken-1" text  @click="closeFilterAndUpdateGrid">
                  Cerrar
                </v-btn>
                
                <v-btn color="primary darken-1" text @click="closeFilterAndUpdateGrid"
                  >Filtrar</v-btn
                >
              </v-card-actions>
                    </div>
                  </div>
                </v-sheet>
              </v-bottom-sheet> 
            </div>
          -->
              <div class="d-flex template-dropdown">
                <v-autocomplete
                  outlined
                  dense
                  :items="TM"
                  item-text="Name"
                  item-value="Id"
                  v-model="template"
                  attach
                  label="Seleccionar plantilla de correo"
                ></v-autocomplete>
              </div>
            </div>
            <div>
              <GeneralFilter
                v-if="filterProducts.typebusiness == 'veterinary'"
                :filterEndpoint="$API.notification
                .getNotificationFiltered"
                class="background border-box"
                entity="veterinary"
                :search="search"
                @emptyFilter="getNotifications"
                @filtered="filterHandler"
              >
                <DxDataGrid class="isScrolledByGeneralFilter"
                  @selection-changed="onSelectionChanged($event)"
                  :data-source="notificationList"
                  :show-row-lines="showRowLines"
                  :show-borders="showBorders"
                  :row-alternation-enabled="rowAlternationEnabled"
                  @exporting="onExporting"
                >
                  <DxExport :enabled="true" />

                  <DxScrolling row-rendering-mode="virtual" />
                  <DxPaging :page-size="10" />
                  <DxColumn data-field="ID_Customer" caption="id"> </DxColumn>
                  <DxColumn
                    cell-template="show-namecustomer"
                    caption="Cliente"
                  ></DxColumn>
                  <DxColumn
                    cell-template="show-emailcustomer"
                    caption="Correo"
                  ></DxColumn>
                  <DxColumn
                    data-field="NamePatient"
                    caption="Nombre Paciente"
                  ></DxColumn>

                  <template #show-namecustomer="{ data }">
                    {{
                      data.data.customer.Is_a_Business
                        ? data.data.customer.BusinessName
                        : data.data.customer.FirstName +
                          " " +
                          data.data.customer.LastName
                    }}
                  </template>

                  <template #show-emailcustomer="{ data }">
                    {{
                      data.data.customer.Is_a_Business
                        ? data.data.customer.BusinessEmail
                        : data.data.customer.Email
                    }}
                  </template>
                  <DxSelection :select-all-mode="allMode" mode="multiple" />
                </DxDataGrid>
              </GeneralFilter>

              <GeneralFilter
                v-if="filterProducts.typebusiness == 'bloodtestlaboratory' || filterProducts.typebusiness == 'medicalclinic'" 
                :filterEndpoint="$API.notification
                .getNotificationFiltered"
                class="background border-box"
                entity="veterinary"
                :search="search"
                @emptyFilter="getNotifications"
                @filtered="filterHandler"
              >
                <DxDataGrid class="isScrolledByGeneralFilter"
                  @selection-changed="onSelectionChanged($event)"
                  :data-source="notificationList"
                  :show-row-lines="showRowLines"
                  :show-borders="showBorders"
                  :row-alternation-enabled="rowAlternationEnabled"
                  @exporting="onExporting"
                >
                  <DxExport :enabled="true" />
                  <DxScrolling row-rendering-mode="virtual" />
                  <DxPaging :page-size="10" />
                  <DxColumn data-field="ID_Customer" caption="id"> </DxColumn>
                  <DxColumn data-field="FirstName" caption="Nombre"></DxColumn>
                  <DxColumn data-field="Email" caption="Correo"></DxColumn>
                  <DxColumn
                    data-field="NamePatient"
                    caption="Nombre paciente"
                  ></DxColumn>
                  <DxSelection :select-all-mode="allMode" mode="multiple" />
                </DxDataGrid>
              </GeneralFilter>

              <GeneralFilter
                v-if="filterProducts.typebusiness == 'accounting_and_bill'"
                :filterEndpoint="$API.notification
                .getNotificationFiltered"
                class="background border-box"
                entity="veterinary"
                :search="search"
                @emptyFilter="getNotifications"
                @filtered="filterHandler"
              >
                <DxDataGrid class="isScrolledByGeneralFilter"
                  @selection-changed="onSelectionChanged($event)"
                  :data-source="notificationList"
                  :show-row-lines="showRowLines"
                  :show-borders="showBorders"
                  :row-alternation-enabled="rowAlternationEnabled"
                  @exporting="onExporting"
                >
                  <DxExport :enabled="true" />
                  <DxScrolling row-rendering-mode="virtual" />
                  <DxPaging :page-size="10" />
                  <DxColumn data-field="ID_Customer" caption="id"> </DxColumn>
                  <DxColumn
                    cell-template="show-namecustomer"
                    caption="Cliente"
                  ></DxColumn>
                  <DxColumn
                    cell-template="show-emailcustomer"
                    caption="Correo"
                  ></DxColumn>
                  <DxSelection :select-all-mode="allMode" mode="multiple" />

                  <template #show-namecustomer="{ data }">
                    {{
                      data.data.Is_a_business == 1
                        ? data.data.BusinessName
                        : data.data.FirstName + " " + data.data.LastName
                    }}
                  </template>

                  <template #show-emailcustomer="{ data }">
                    {{
                      data.data.Is_a_business == 1
                        ? data.data.BusinessEmail
                        : data.data.Email
                    }}
                  </template>

                </DxDataGrid>
              </GeneralFilter>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-expansion-panel-content>

    <v-dialog v-model="showModal" max-width="800">
      <modal>
        <template slot="title">Send Reminder</template>
        <template slot="body">
          <ValidationObserver
            ref="observer"
            tag="form"
            v-slot="{ handleSubmit }"
          >
            <form @submit.prevent="handleSubmit(save)">
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <ValidationProvider
                    name="Phone"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      outlined
                      dense
                      :error-messages="errors"
                      v-model="form.phone"
                      label="Phone"
                      type="number"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <ValidationProvider
                    name="Email"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      outlined
                      dense
                      :error-messages="errors"
                      v-if="email"
                      v-model="form.email"
                      label="Email"
                      type="email"
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <date-picker
                    label="From"
                    @dateSelected="(param) => (form.From = param)"
                  ></date-picker>
                  <div class="v-text-field__details" v-if="fromError">
                    <div
                      class="v-messages theme--light error--text"
                      role="alert"
                    >
                      <div class="v-messages__wrapper">
                        <div class="v-messages__message">
                          The From field is required
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" sm="12" md="3">
                  <date-picker
                    label="to"
                    @dateSelected="(param) => (form.to = param)"
                  ></date-picker>
                  <div class="v-text-field__details" v-if="toError">
                    <div
                      class="v-messages theme--light error--text"
                      role="alert"
                    >
                      <div class="v-messages__wrapper">
                        <div class="v-messages__message">
                          The To field is required
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <ValidationProvider
                    name="Email Templates"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      outlined
                      dense
                      :error-messages="errors"
                      v-model="form.emailTemplate"
                      :items="emails"
                      item-text="EmailName"
                      item-value="Email_Id"
                      label="Email Templates"
                      attach
                    ></v-select>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <a
                    href="#"
                    class="mx-5"
                    @click.prevent.stop="open"
                    v-if="form.emailTemplate"
                  >
                    <v-icon class="mt-5" color="primary">mdi-eye</v-icon>
                  </a>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-btn color="error darken-1" text @click="closeReminder">
                  Close
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success darken-1" type="submit" @click="save"
                  >Save</v-btn
                >
              </v-card-actions>
            </form>
          </ValidationObserver>
        </template>
      </modal>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="auto">
      <v-card>
        <v-card-title class="text-h5"> {{ formTitle }}</v-card-title>
        <v-card-text v-if="form.emailTemplate">
          <v-row>
            <v-col cols="12" md="12" v-html="this.emailBody.html"> </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="close"> Close </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    ></alerts>
  </v-expansion-panel>
</template>

<script>
import GeneralFilter from "@/components/GeneralFilter";
import DatePicker from "../../../components/DatePicker";
import Modal from "../../../components/Modal";
import Alerts from "@/components/Alerts";
import { printToExcel } from "@/helpers/printToexcel";
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxPaging,
  DxSelection,
  DxExport,
} from "devextreme-vue/data-grid";
import Block from "@/components/Block";
import VueSlider from "vue-slider-component";
import { mapState } from "vuex";
import "vue-slider-component/theme/default.css";

export default {
  name: "Notifications",
  components: {
    Modal,
    DatePicker,
    DxDataGrid,
    DxColumn,
    Block,
    DxScrolling,
    DxPaging,
    VueSlider,
    DxSelection,
    DxExport,
    Alerts,
    GeneralFilter,
  },
  props: ["win"],
  data() {
    return {
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      reload: 0,
      fromError: false,
      toError: false,
      dialog: false,
      showFilter: false,
      showModal: false,
      email: false,
      loading: false,
      form: {},
      notifications: [],
      notificationList: [],
      TM: [],
      template: {},
      state: {},
      filterProducts: {
        kind: null,
        typeusers: "Todos",
        gender: null,
        breed: null,
        city: null,
        state: null,
        birthday: null,
        age: [0, 0],
        idbusiness: JSON.parse(localStorage.getItem("user")).businessid || "",
        idbranch: JSON.parse(localStorage.getItem("user")).branch || "",
        typebusiness:
          JSON.parse(localStorage.getItem("user")).businesstype || "",
      },
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      emails: [],
      emailBody: {
        html: "",
        design: "",
      },
      formTitle: "Template Design",
      dataMail: [],
      allMode: "allPages",
      selectedRowsData: [],
      isDeselect: false,
      notificacionMain: [],
      typebusiness: JSON.parse(localStorage.getItem("user")).businesstype || "",
      filtersData: {
        kind: [],
        breed: [],
        city: [],
        state: [],
        birthday: [],
        Gender: [],
        age: [0, 0],
      },
      useBirthday: true,
      search: {
        filter: null,
        filterObj: {}
      }
    };
  },
  watch: {
    showModal() {
      if (!this.showModal) {
        this.email = false;
      }
    },
    //   filterProducts: {
    //   deep: true,
    //   handler: "getNotifications",
    // },
  },
  mounted() {
    this.$store.dispatch("rolesUser/getAllRoles");
    this.getAllEmails();
    this.getNotifications();
    this.getAllTemplateModule();
  },

  computed: {
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
  },

  methods: {
    onExporting(e) {
      printToExcel(e, "Listado de notificaciones");
    },
    reloadALL() {
      this.filterProducts = {
        kind: null,
        typeusers: "Todos",
        gender: null,
        breed: null,
        city: null,
        state: null,
        birthday: null,
        age: [0, 0],
        idbusiness: JSON.parse(localStorage.getItem("user")).businessid || "",
        idbranch: JSON.parse(localStorage.getItem("user")).branch || "",
        typebusiness:
          JSON.parse(localStorage.getItem("user")).businesstype || "",
      };
      this.reload++;
      this.getAllEmails();
      this.getNotifications();
      this.getAllTemplateModule();
    },
    closeAlert() {
      this.alert.show = false;
    },
    acceptAlert() {
      this.alert.show = false;
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    getAllEmails() {
      this.$API.email.getEmails().then((response) => {
        this.emails = response;
      });
    },
    open() {
      this.emails.map((items) => {
        if (items.Email_Id == this.form.emailTemplate)
          this.emailBody = JSON.parse(items.Email_Body);
      });

      this.dialog = true;
    },
    closeReminder() {
      this.showModal = false;
      this.email = false;
      this.$refs.observer.reset();
    },
    close() {
      this.dialog = false;
    },
    closeFilterAndUpdateGrid() {
      this.showFilter = !this.showFilter;
      // this.getNotifications();
      this.aplyallFilters();
      location.reload();
    },
    aplyallFilters() {
      this.notificationList = this.notificacionMain;
      if (this.filterProducts.typebusiness == "bloodtestlaboratory") {
        if (this.filterProducts.gender) {
          this.notificationList = this.notificationList.filter((x) => {
            return x.Gender == this.filterProducts.gender;
          });
        }
        if (
          this.filterProducts.age[0] != 0 ||
          this.filterProducts.age[1] != 0
        ) {
          this.notificationList = this.notificationList.filter((x) => {
            return (
              x.Age >= this.filterProducts.age[0] &&
              x.Age <= this.filterProducts.age[1]
            );
          });
        }
        if (this.filterProducts.birthday && this.useBirthday) {
          this.notificationList = this.notificationList.filter((x) => {
            return x.DateOfBirth == this.filterProducts.birthday;
          });
        }
        if (this.filterProducts.city) {
          this.notificationList = this.notificationList.filter((x) => {
            return x.City == this.filterProducts.city;
          });
        }
        if (this.filterProducts.state) {
          this.notificationList = this.notificationList.filter((x) => {
            return x.Country == this.filterProducts.state;
          });
        }
        if (this.filterProducts.typeusers == "Clientes") {
          this.notificationList = this.notificationList.filter((x) => {
            return x.Is_a_business == 0;
          });
        }
        if (this.filterProducts.typeusers == "Negocios") {
          this.notificationList = this.notificationList.filter((x) => {
            return x.Is_a_business == 1;
          });
        }
      } else if (this.filterProducts.typebusiness == "veterinary") {
        if (this.filterProducts.gender) {
          this.notificationList = this.notificationList.filter((x) => {
            return x.Gender == this.filterProducts.gender;
          });
        }
        if (this.filterProducts.kind) {
          this.notificationList = this.notificationList.filter((x) => {
            return x.Species == this.filterProducts.kind;
          });
        }
        if (this.filterProducts.breed) {
          this.notificationList = this.notificationList.filter((x) => {
            return x.Breed == this.filterProducts.breed;
          });
        }
        if (this.filterProducts.city) {
          this.notificationList = this.notificationList.filter((x) => {
            return x.customer.City == this.filterProducts.city;
          });
        }
        if (this.filterProducts.state) {
          this.notificationList = this.notificationList.filter((x) => {
            return x.customer.Country == this.filterProducts.state;
          });
        }
        if (this.filterProducts.birthday && this.useBirthday) {
          this.notificationList = this.notificationList.filter((x) => {
            return x.DateOfBirth == this.filterProducts.birthday;
          });
        }
        if (
          this.filterProducts.age[0] != 0 ||
          this.filterProducts.age[1] != 0
        ) {
          this.notificationList = this.notificationList.filter((x) => {
            return (
              x.Age >= this.filterProducts.age[0] &&
              x.Age <= this.filterProducts.age[1]
            );
          });
        }
      }
    },
    cleanFilterparams() {
      this.filterProducts = {
        kind: null,
        typeusers: "Todos",
        gender: null,
        breed: null,
        city: null,
        state: null,
        birthday: null,
        age: [0, 0],
        idbusiness: JSON.parse(localStorage.getItem("user")).businessid || "",
        idbranch: JSON.parse(localStorage.getItem("user")).branch || "",
        typebusiness:
          JSON.parse(localStorage.getItem("user")).businesstype || "",
      };
      this.useBirthday = false;
      this.aplyallFilters();
    },
    async save() {
      this.$refs.observer.validate();
      const isValid = await this.$refs.observer.validate();
      if (!this.form.from && !this.form.to) {
        this.fromError = true;
        this.toError = true;
        return;
      }
      this.fromError = false;
      this.toError = false;
      let obj = {
        to: "ark61996@gmail.com",
        subject: "test",
        html: this.emailBody.html,
        type: "",
      };

      if (isValid) {
        this.$API.thirdapi
          .sendEmail(obj)
          .then((response) => {
            console.log(response);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getNotifications() {
      this.loading = true;
      //remember to send type of business
      //let filterObj = {};
      let filterObj = { ...this.filterProducts };
      Object.keys(filterObj).map((key) => {
        if (filterObj["age"][0] == 0 && filterObj["age"][1] == 0) {
          filterObj["age"] = [];
        }
        if (filterObj[key] === true || filterObj[key] == null)
          delete filterObj[key];
      });
      this.search.filterObj = filterObj;
      this.$API.notification
        .getNotificationHistory(filterObj)
        .then((response) => {
          this.loading = false;
          this.notificationList = response;
          console.log(this.notificationList);
          this.notificacionMain = response;
          if (this.filterProducts.typebusiness == "veterinary")
            this.notificationList.forEach((element) => {
              if (this.filtersData.kind.indexOf(element.Species) == -1) {
                this.filtersData.kind.push(element.Species);
              }
              if (this.filtersData.breed.indexOf(element.Breed) == -1) {
                this.filtersData.breed.push(element.Breed);
              }
              if (this.filtersData.city.indexOf(element.customer.City) == -1) {
                if (element.customer.City != "")
                  this.filtersData.city.push(element.customer.City);
              }
              if (
                this.filtersData.state.indexOf(element.customer.Country) == -1
              ) {
                if (element.customer.Country != "")
                  this.filtersData.state.push(element.customer.Country);
              }
              if (this.filtersData.Gender.indexOf(element.Gender) == -1) {
                if (element.Gender != "")
                  this.filtersData.Gender.push(element.Gender);
              }
            });

          if (this.filterProducts.typebusiness == "bloodtestlaboratory")
            this.notificationList.forEach((element) => {
              if (this.filtersData.Gender.indexOf(element.Gender) == -1) {
                if (element.Gender != "")
                  this.filtersData.Gender.push(element.Gender);
              }
              if (this.filtersData.city.indexOf(element.City) == -1) {
                if (element.City != "")
                  this.filtersData.city.push(element.City);
              }
              if (this.filtersData.state.indexOf(element.State) == -1) {
                if (element.State != "")
                  this.filtersData.state.push(element.State);
              }
            });
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // Send email to client
    sendEmail() {
      this.loading = true;
      this.TM.forEach((element) => {
        if (element.Id == this.template) {
          this.template = element;
          return;
        }
      });
      if (this.dataMail.length < 1 || !this.template) {
        this.showAlert(
          "danger",
          "Error",
          "Primero seleccione una plantilla y/o clientes"
        );
        this.loading = false;
        return;
      }
      let data = {
        mailBoxData: this.dataMail,
        templateData: this.template,
      };

      this.$API.notification
        .sendVeterinaryReminder(data)
        .then(() => {
          this.loading = false;
          this.showAlert(
            "success",
            "Notificacion enviada",
            "Se ha enviado la notificacion correctamente"
          );
        })
        .catch(() => {
          this.loading = false;
          this.showAlert(
            "danger",
            "Error",
            "Ha ocurrido un error al enviar la notificacion"
          );
        });
    },
    /*  
    Old Version
     const jsonArray={
      templateName: this.template.name,
      type:this.template.type,
      obj:this.dataMail,
      ID_Business: JSON.parse(localStorage.getItem("user")).businessid
    } */
    //alert(this.template.name)
    /* if(JSON.stringify(jsonArray.obj)==='[]' || !jsonArray.templateName){
      this.showAlert('danger','Error','Primero seleccione un template y/o clientes');
      this.loading = false;
      return;
    }
   
    this.$API.notification.addMail(jsonArray).then((response) =>{
        this.loading = false;
        if(response.data){
          this.showAlert('success','Exito', 'Los correos se han enviado correctamente');
        }else{
          this.loading = false;
          this.showAlert('danger','Error', "Error al enviar el correo, por favor verifique la configuración de envío de correos eléctronicos.");
        }
    }) */
    //},

    // GET-ALL-TEMPLATES
    getAllTemplateModule() {
      this.$API.TM.getTM().then((response) => {
        response = response.filter((x) => {
          return x.Type == "Notification" || x.Type == "Birthday";
        });
        this.TM = response;
      });
    },
    selectTemplate(template) {
      this.template = {
        type: template[0].Type,
        name: template[0].Name,
      };

      //alert(JSON.stringify(this.template))
    },

    onSelectionChanged(event) {
      this.selectedRowsData = event.selectedRowsData;
      if (event.currentDeselectedRowKeys.length > 0) {
        this.isDeselect = true;
        Object.assign(
          {},
          ...event.currentDeselectedRowKeys.map((x) => {
            this.dataMail.splice(
              this.dataMail.findIndex(
                (item) => item.ID_Patient === x.ID_Patient
              ),
              1
            );
          })
        );
      } else {
        this.isDeselect = false;
      }
      if (this.isDeselect == false) {
        this.dataMail = [];
        var parsedobj = JSON.parse(JSON.stringify(this.selectedRowsData));
        Object.assign(
          {},
          ...parsedobj.map((x) => {
            this.dataMail.push(x);
          })
        );
      }
    },
    //make filterhandler function
    filterHandler(response) {
      console.log(response)
      this.notificationList = response;
      console.log(this.notificationList);
      this.notificacionMain = response;
      if (this.filterProducts.typebusiness == "veterinary")
        this.getNotificationHistory.forEach((element) => {
          if (this.filtersData.kind.indexOf(element.Species) == -1) {
            this.filtersData.kind.push(element.Species);
          }
          if (this.filtersData.breed.indexOf(element.Breed) == -1) {
            this.filtersData.breed.push(element.Breed);
          }
          if (this.filtersData.city.indexOf(element.customer.City) == -1) {
            if (element.customer.City != "")
              this.filtersData.city.push(element.customer.City);
          }
          if (this.filtersData.state.indexOf(element.customer.Country) == -1) {
            if (element.customer.Country != "")
              this.filtersData.state.push(element.customer.Country);
          }
          if (this.filtersData.Gender.indexOf(element.Gender) == -1) {
            if (element.Gender != "")
              this.filtersData.Gender.push(element.Gender);
          }
        });

      if (this.filterProducts.typebusiness == "bloodtestlaboratory")
        this.notificationList.forEach((element) => {
          if (this.filtersData.Gender.indexOf(element.Gender) == -1) {
            if (element.Gender != "")
              this.filtersData.Gender.push(element.Gender);
          }
          if (this.filtersData.city.indexOf(element.City) == -1) {
            if (element.City != "") this.filtersData.city.push(element.City);
          }
          if (this.filtersData.state.indexOf(element.State) == -1) {
            if (element.State != "") this.filtersData.state.push(element.State);
          }
        });
    },
  },
};
</script>

<style scoped>
.filter-btn {
  top: 10px;
  left: 10px;
  z-index: 1;
}
.temp-dimensions {
  max-width: 100px;
  max-height: 300px;
}

.unlayer-editor {
  height: 1000px !important;
}
.unlayer-editor iframe {
  min-width: auto !important;
}
.template-dropdown {
  top: 10px;
  right: 181px;
  z-index: 1;
}
.btnclean {
  background-color: #f29d35 !important;
  color: white;
}
.btnsave {
  background-color: #00a178 !important;
  color: white;
}
.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}
</style>
